const SkipAnimationState = {
  skip: false,
  listeners: [],

  setSkip(value) {
    this.skip = value;
    this.notifyListeners();
  },

  addListener(listener) {
    this.listeners.push(listener);
  },

  notifyListeners() {
    this.listeners.forEach(listener => listener(this.skip));
  }
};

export default SkipAnimationState;
