import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "@rails/ujs"

// Connects to data-controller="async-button"
export default class extends Controller {
  static targets = ["button", "spinner", "result", "date"]

  submit(event) {
    event.preventDefault()
    const button = this.buttonTarget
    const url = button.getAttribute("formaction") || button.getAttribute("href")
    const method = button.getAttribute("data-method") || "get"

    // Hide the button and show the spinner
    button.classList.add("hidden")
    this.spinnerTarget.classList.remove("hidden")

    // Make the AJAX request
    fetch(url, {
      method: method.toUpperCase(),
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        this.spinnerTarget.classList.add("hidden")
        this.resultTarget.classList.remove("hidden")
        this.resultTarget.textContent = data.message
        if (data.date) {
          this.dateTarget.classList.remove("hidden")
          this.dateTarget.textContent = data.date
        }
      })
      .catch(error => {
        console.error("Error:", error)
        this.spinnerTarget.classList.add("hidden")
        this.resultTarget.classList.remove("hidden")
        this.resultTarget.textContent = "An error occurred. Please try again."
      })
  }
}
