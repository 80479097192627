const colors = {
  // Background colors
  bgEmerald50: 'rgb(236 253 245 / var(--tw-bg-opacity, 1))',
  bgEmerald300: 'rgb(110 231 183 / var(--tw-bg-opacity, 1))',
  bgOrange50: 'rgb(255 247 237 / var(--tw-bg-opacity, 1))',
  bgOrange400: 'rgb(251 146 60 / var(--tw-bg-opacity, 1))',
  bgRose50: 'rgb(255 241 242 / var(--tw-bg-opacity, 1))',
  bgRose400: 'rgb(251 113 133 / var(--tw-bg-opacity, 1))',
  bgSky50: 'rgb(240 249 255 / var(--tw-bg-opacity, 1))',
  bgSky300: 'rgb(125 211 252 / var(--tw-bg-opacity, 1))',
  // Text colors
  textEmerald800: 'rgb(6 95 70 / var(--tw-text-opacity, 1))',
  textGray500: 'rgb(107 114 128 / var(--tw-text-opacity, 1))',
  textOrange800: 'rgb(154 52 18 / var(--tw-text-opacity, 1))',
  textRose900: 'rgb(136 19 55 / var(--tw-text-opacity, 1))',
  textSky800: 'rgb(12 74 110 / var(--tw-text-opacity, 1))'
};

export default colors;
